export const InstitutionalWelcomeFugini = () => import('./institutional-welcome-fugini');
export const InstitutionalCardWelcomeFugini = () => import('./institutional-card-welcome-fugini');
export const InstitutionalBannerCategory = () => import('./institutional-banner-category');
export const InstitutionalCardSuccessStory = () => import('./Institutional-card-success-story');
export const InstitutionalSuccessStory = () => import('./institutional-success-story');
export const InstitutionalHeader = () => import('./institutional-header');
export const InstitutionalLinkHeader = () => import('./institutional-link-header');
export const InstitutionalForm = () => import('./institutional-form');
export const InstitutionalCardQuiz = () => import('./institutional-card-quiz');
export const InstitutionalQuestionQuiz = () => import('./institutional-question-quiz');
export const InstitutionalContainerQuiz = () => import('./institutional-container-quiz');
export const InstitutionalBreadcrumb = () => import('./institutional-breadcrumb');
export const InstitutionalStripeBanner = () => import('./institutional-stripe-banner');
export const InstitutionalSuccessQuiz = () => import('./institutional-success-quiz');
export const InstitutionalExploreFugini = () => import('./institutional-explore-fugini');
export const InstitutionalCardExploreFugini = () => import('./institutional-card');
export const InstitutionalFooter = () => import('./institutional-footer');
export const InstitutionalFooterLinksList = () => import('./institutional-footer-links-list');
export const InstitutionalFooterLinksListContainer = () => import('./institutional-footer-links-list-container');
export const InstitutionalBanner = () => import('./institutional-banner');
export const InstitutionalBannerCarousel = () => import('./institutional-banner-carousel');
export const InstitucionalNewsletter = () => import('./institucional-newsletter');
export const InstitutionalCardProducingRegions = () => import('./institutional-card-regions-producing');
export const InstitutionalContainerProducingRegions = () => import('./institutional-container-producing-regions');
export const InstitutionalCardStepsField = () => import('./institutional-card-steps-field');
export const InstitutionalStepsField = () => import('./institutional-steps-field');
export const InstitutionalPresentationMode = () => import('./institutional-presentation-mode');
export const InstitutionalCardPresentationMode = () => import('./institutional-card-presentation-mode');
export const InstitutionalProductionMode = () => import('./institutional-production-mode');
export const InstitutionalBannerCardProductionMode = () => import('./institutional-banner-card-production-mode');
